import { UploadAction } from "../actions/uploadActions";
import _ from "lodash";
import { SUB_HEADING_TAB } from "../../components/UploadNotification/data";

const initialState = {
    uploadProgress: null,
    successFiles: null,
    failedFiles: null,
    getFilteredFiles: null,
    isVisible: true,
    scrollTopVisible: false,
    activeTab: SUB_HEADING_TAB[0],
    statusCount: {
        totalCount: 0,
        successCount: 0,
        failedCount: 0,
        cancelledCount: 0,
    },
    signedUrlOutputs: {},
    processedFiles: [],
    studyIds: [],
    isWidgetVisible: false,
};

const uploadReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UploadAction.UpdateUploadProgress:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.UploadSuccess:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.UploadFailed:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.RefetchFiles:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.ClearUploadNotification:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.CancelUpload:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.CancelAllUpload:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.UpdateStatusCount:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.UpdateScrollTopVisible:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.UpdateNotificationVisibilty:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.SetUploadNotiTab:
            return {
                ...state,
                ...{ activeTab: payload },
            };
        case UploadAction.RetryStarted:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.RetryAllUploadStarted:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.SignedUrlOutputs:
            return {
                ...state,
                ...payload,
            };
        case UploadAction.ProcessedFiles:
            return {
                ...state,
                ...payload,
            };

        case UploadAction.StudyIds:
            return {
                ...state,
                ...payload,
            };

        case UploadAction.UpdateWidgetVisibleStatus:
            return {
                ...state,
                ...payload,
            };

        // case UploadAction.FilterFiles:
        //     let sortedSuccessValues = _.orderBy(
        //         Object.values(state.successFiles || {}),
        //         ["idx"],
        //         ["asc"],
        //     );
        //     state.successFiles = {
        //         ...(sortedSuccessValues || {}),
        //     };
        //     return {
        //         ...state,
        //         getFilteredFiles:
        //             payload.filterType === "failedFiles"
        //                 ? {
        //                       ..._.xorBy(
        //                           Object.values(state.uploadProgress || {}),
        //                           sortedSuccessValues,
        //                           "idx",
        //                       ),
        //                   }
        //                 : state[payload.filterType],
        //     };
        default:
            return state;
    }
};

export default uploadReducer;
