const environment = (typeof window !== "undefined" && window._conf) || {
    ENV: "development",
    VALUE: "development",
    PORT: 9090,
    NAT_IP: "152.0.0.0",
    KAKASHI_MAIN_URL: `https://api.mrgx0.de/kakashi`,
    APP_CDN_BASE_URL: "https://cdn-1.erasez0.de/",
    STORAGE_FILE_ERASEBG_CDN_ASSETS: "s3://erase-erasex0-erasebg-assets-ap-south-1/",
    // APP_STRIPE_SECRET_KEY:
    //     "pk_test_51MxmtESGQDEzzFOAEuaD2xZTe8R5NBQQhsQqfNshuaus0YYx96pa3gPYUEntZthtvc40O6T39VQq7TzDUyaCSVlv005jZFybb4",
    APP_STRIPE_PUBLISHABLE_KEY:
        "pk_test_51KP0CaSIcn3d6hi2v1njD0FfcKmMPSeZjL7ZgJn9JD5hZ6TszcEtETRHiOFRTYSduWJ1ukKnXHHAK6vrvg33ydwz00SEpHKBZJ",
    APP_SIGNATURE_SECRET_KEY: "dda3acf671474662b8cdde080bd8cac5",
    RECAPTCHA_SITE_KEY: "6Ld-AAwdAAAAABndOF9Ci5gy1CvIgN78FOzKQnw4",
    RECAPTCHA_KEY: "6Ld-AAwdAAAAABndOF9Ci5gy1CvIgN78FOzKQnw4",
    GOOGLE_CLIENT_ID: "554548215700-c9mev1c4k9jaserprd89bqt4ohphtekv.apps.googleusercontent.com",
    GOOGLE_FRONTEND_APP_ID:
        "554548215700-c9mev1c4k9jaserprd89bqt4ohphtekv.apps.googleusercontent.com",
    SAKURA_MAIN_DOMAIN: "https://www.erasez0.de/",
    SARADA_MAIN_DOMAIN: "https://www.upscalez0.de/",
    SUIGETSU_MAIN_DOMAIN: "https://www.watermarkremoverz0.de/",
    SAI_MAIN_DOMAIN: "https://www.shrinkz0.de/",
    SATETSU_MAIN_DOMAIN: "https://www.convertfilesz0.de/",
    // LAVA_MAIN_DOMAIN: "https://console.mrgx0.de/",
    LAVA_MAIN_DOMAIN: "https://local.mrgx0.de:9090/",

    STEALTH_MAIN_URL: "https://api.mrgx0.de/service/panel/users",
    STEALTH_WEBHOOK_URL: "https://api.mrgx0.de/service/webhook/users",
    HYDRO_MAIN_URL: "https://api.mrgx0.de/service/panel/organization",
    HULKBUSTER_MAIN_URL: "https://api.mrgx0.de/service/panel/patients",

    CHOJI_PNL_URL: "https://api.pixelbinz0.de/service/panel/assets",
    // CHOJI_PNL_URL: "http://localhost:9001",
    GAARA_MAIN_URL: "https://api.pixelbinz0.de/service/panel/notification",
    KAKASHI_PNL_URL: "https://api.pixelbinz0.de/service/panel/kakashi",
    ITACHI_PNL_URL: "https://api.pixelbinz0.de/service/panel/analytics",
    ERASEBG_STORAGE_S3_FOLDER: "uploads",
    SAMUI_MAIN_DOMAIN: "https://www.pixelbinz0.de/docs/",
    DEFAULT_PLAYGROUND_ASSET_URL:
        "https://cdn.pixelbinz0.de/dummy-cloudname/original/original/5c790681-bf5b-4c4f-a53b-69a4ca5c7c85.jpeg",
    JIRAIYA_PNL_URL: "https://api.pixelbinz0.de/service/panel/transformation",
    // JIRAIYA_PNL_URL: "http://localhost:9069",
    JIRAIYA_CDN_DOMAIN: "https://cdn.pixelbinz0.de",
    JIRAIYA_WEBHOOK_URL: "https://api.pixelbinz0.de/service/webhook/transformation",
    // HINATA_MAIN_DOMAIN: "https://local.pixelbinz0.de:9090",
    RUPIKA_PNL_URL: "https://api.pixelbinz0.de/service/panel/payment",
    SHIRAYUKI_PNL_URL: "https://api.pixelbinz0.de/service/panel/auditLogs",
    // RUPIKA_PNL_URL: "http://localhost:9081",
    GTM_CONTAINER_ID: "GTM-NBNFXGR9",
    // USERSNAP_API_KEY: "42e6d454-dc78-4ac0-91f6-eb845e20ced6",
    USERSNAP_API_KEY: "84de98e8-9dc7-4c84-8a46-a83318bc233c",
    API_MAIN_DOMAIN: "https://api.mrgx0.de",
    newrelic_app: "",
    newrelic_license_key: "",
    INR_CONVERSION_RATE: 81,
    GST_RATE: 18,
    CGST_RATE: 9,
    SGST_RATE: 9,
    FREE_PLAN_ID: -992,
    CLOUDFLARE_CUSTOM_ORIGIN: "cdn-1.pixelbinz0.de",
    CLOUDFLARE_ZONE_ID: "cfc4ca53acf8f1b8f7b9c6a69d1bc965",
    maxSignedUrlExpiry: 21600,
    GLAMAR_CONSOLE_DOMAIN: "https://console.glamarz0.de",
    KIBA_MAIN_DOMAIN: "https://glamarz0.de",
    APPLE_OAUTH_DEFAULT_NAME: "Anon-5fb8c939",
    BORUTO_PUBLIC_URL: "https://api.mrgx0.de/cms",
    PADDLE_USERS: "",
    OPTIONAL_PADDLE: false,
    PADDLE_CLIENT_SIDE_TOKEN: "test_8b6d6c0f76f361c5b332f9ef8d2",
    SENTRY_DSN:
        "https://dbed3db6f9ec37351b1b1b851e864d7c@o71740.ingest.us.sentry.io/4507741591437312",
};

export default environment;
