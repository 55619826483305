import UserService from "../../../services/user.service";
import OrganizationService from "../../../services/org.service";

export const OrgAction = {
    CreateOrganizationSuccess: "CREATE_ORGANIZATION_SUCCESS",
    CreateOrganizationFailed: "CREATE_ORGANIZATION_FAILED",
    GetOrganizationDetails: "GET_ORGANIZATION_DETAILS",
    GetOrganizationPreference: "GET_ORGANIZATION_PREFERENCE",
    GetOrganizationsForUser: "GET_ORGANIZATIONS",
    SetCurrentOrganization: "SET_CURRENT_ORGANIZATION",
    updateCurrentOrganization: "UPDATE_CURRENT_ORGANIZATION",
    Logout: "LOGOUT",
    UpdateSelectedHospital: "UPDATE_SELECTED_HOSPITAL",
    SetOrganizationLocation: "SET_ORGANIZATION_LOCATION",
};

export const createOrganization = (formData, dispatch) => {
    return new Promise((resolve, reject) => {
        UserService.createOrganization(formData)
            .then((res) => {
                dispatch({
                    type: OrgAction.CreateOrganizationSuccess,
                    payload: { isOrgRegistered: true, organization: res.data.detail },
                });
                resolve(res.data);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: OrgAction.CreateOrganizationFailed,
                    payload: { isOrgRegistered: false, organization: null },
                });
                reject(err);
            });
    });
};

export const updateOrganizationDetails = (data, orgId, dispatch) => {
    return new Promise((resolve, reject) => {
        OrganizationService.updateOrg(data, orgId)
            .then((res) => {
                dispatch({
                    type: OrgAction.updateCurrentOrganization,
                    payload: { organization: res.data.data },
                });
                resolve(res.data.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

/* export const getOrganizationDetails = (dispatch) => {
  return new Promise((resolve, reject) => {
    UserService.getOrganizationDetails()
      .then((res) => {
        dispatch({
          type: OrgAction.GetOrganizationDetails,
          payload: { isOrgRegistered: true, organization: res.data },
        });
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: OrgAction.GetOrganizationDetails,
          payload: { isOrgRegistered: false, organization: null },
        });
        reject(err);
      });
  });
}; */

export const getOrganizationsForUser = (dispatch) => {
    return new Promise((resolve, reject) => {
        UserService.getOrganizationsForUser()
            .then((res) => {
                dispatch({
                    type: OrgAction.GetOrganizationsForUser,
                    payload: { organizations: res.data },
                });
                resolve(res.data);
            })
            .catch((err) => {
                dispatch({
                    type: OrgAction.GetOrganizationsForUser,
                    payload: { organizations: [] },
                });
                reject(err);
            });
    });
};

export const setCurrentOrganization = (org, member, selectedHospital, dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: OrgAction.SetCurrentOrganization,
            payload: { organization: org, member, selectedHospital },
        });
        resolve();
    });
};

export const updateSelectedHospital = (selectedHospitalId, dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: OrgAction.UpdateSelectedHospital,
                payload: { selectedHospital: selectedHospitalId },
            });
            resolve(selectedHospitalId);
        } catch (err) {
            reject(err);
        }
    });
};

export const setOrganizationLocation = (orgLocation, dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch({
                type: OrgAction.SetOrganizationLocation,
                payload: { organizationLocation: orgLocation },
            });
            resolve(orgLocation);
        } catch (err) {
            reject(err);
        }
    });
};
