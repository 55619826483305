import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import environment from "./src/environment";

if (environment.SENTRY_DSN) {
    Sentry.init({
        dsn: environment.SENTRY_DSN,
        enabled: false,

        integrations: [
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
            // Sentry.BrowserTracing({ tracingOrigins: ["*"] }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        tracesSampleRate: 0.1, // Reduce from 1.0 to 0.1 (10% of transactions)
        replaysSessionSampleRate: 0.05, // Reduce from 0.1 to 0.05 (5% of sessions)
        replaysOnErrorSampleRate: 0.5,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [/^\//, /^https:\/\/api\.mrgx0\.de/],
        allowUrls: [window.location.origin, /^\//, /^https:\/\/api\.mrgx0\.de/],
        transportOptions: {
            // Add custom headers if needed
            headers: {
                "Access-Control-Allow-Origin": "*",
                // Add any other required headers
            },
        },

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // replaysSessionSampleRate: 0.1,
        // replaysOnErrorSampleRate: 1.0,
    });
}
