import React, { createContext, useState, useContext } from "react";

const EchoPreviewContext = createContext();

export const useEchoPreview = () => useContext(EchoPreviewContext);

export const EchoPreviewProvider = ({ children }) => {
    const [selectedStudy, setSelectedStudy] = useState(null);

    const openPreview = (study) => {
        setSelectedStudy(study);
    };

    const closePreview = () => {
        setSelectedStudy(null);
    };

    return (
        <EchoPreviewContext.Provider value={{ selectedStudy, openPreview, closePreview }}>
            {children}
        </EchoPreviewContext.Provider>
    );
};
