const publicPropButtonThemes = {
    erasebg: { gradient: "--brand-gradient-erasebg", primary: "--primary-erasebg" },
    upscalemedia: { gradient: "--brand-gradient-upscalemedia", primary: "--primary-upscalemedia" },
    watermarkremover: {
        gradient: "--brand-gradient-watermarkremover",
        primary: "--primary-watermarkremover",
    },
    shrinkmedia: { gradient: "--brand-gradient-shrink", primary: "--primary-erasebg-shrink" },
};

const publicProperty = {
    ERASE_BG: "erasebg",
    UPSCALE_MEDIA: "upscalemedia",
    WATERMARKREMOVER_IO: "watermarkremover",
    SHRINK_MEDIA: "shrinkmedia",
    GLAMAR: "glamar",
};

const btnStyleName = {
    white: {
        bg: "--white",
        hover: "--white",
    },
    cta: {
        bg: "--primary",
        hover: "--primary",
    },
    primary: {
        bg: "--primary",
        hover: "--primary",
    },
    primaryLight: {
        bg: "--primary-light",
        hover: "--primary-light",
    },
    secondary: {
        bg: "--dark-700",
        hover: "--dark-700",
    },
    tertiary: {
        bg: "--tertiary",
        hover: "--tertiary",
    },
    danger: {
        bg: "--danger",
        hover: "--danger",
    },
};

export { publicPropButtonThemes, publicProperty, btnStyleName };
