import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

const StyledReactTooltip = styled(ReactTooltip)`
    font-style: var(--body-font) !important;
    font-size: 12px !important;
    padding: 8px !important;
    line-height: 22px !important;
    height: auto !important;
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth} !important` : "210px !important")};
    word-wrap: break-word;

    ${(props) =>
        props.noArrow &&
        `
        ::before,
        ::after {
            content: none !important;
        }
    `}
`;

export default function Tooltip({ children, type = "dark", enableToolTip = true, ...restProps }) {
    let textColor = "light";
    let bgColor = type === "faint" ? "disabled" : type;

    switch (bgColor) {
        case "white":
        case "light":
        case "success":
        case "info":
        case "warning":
            textColor = "dark";
            break;
        case "error":
            bgColor = "upload-bg-error";
            break;
        case "faint":
            textColor = "light";
            break;
    }

    return (
        enableToolTip && (
            <StyledReactTooltip
                backgroundColor={`var(--${bgColor})`}
                textColor={`var(--${textColor})`}
                {...restProps}
            >
                {children}
            </StyledReactTooltip>
        )
    );
}
