import React, { createContext, useContext, useState } from "react";

// Initial state setup
const initialState = {
    orgHospitalId: "",
    setOrgHospitalId: () => {},
    // showTopNavbar: false,
    // setShowTopNavbar: () => {},
};

// Create the context
const AppContext = createContext(initialState);

// Export the hook to use the context
export const useAppContext = () => useContext(AppContext);

// Provider component
export const AppProvider = ({ children }) => {
    const [orgHospitalId, setOrgHospitalId] = useState(null);
    // const [showTopNavbar, setShowTopNavbar] = useState(false);

    const value = {
        orgHospitalId,
        setOrgHospitalId,
        // showTopNavbar,
        // setShowTopNavbar,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
