import environment from "../../environment";

// function replacePlatformDomain(originalUrl) {
//     return originalUrl.replace(environment.HINATA_MAIN_DOMAIN, environment.SAKURA_MAIN_DOMAIN);
// }

const URLS = {
    // NEJI URLs
    registerUser: `${environment.STEALTH_MAIN_URL}/v1.0/authentication/register`,
    loginUser: `${environment.STEALTH_MAIN_URL}/v1.0/authentication/login/password`,
    setCookieToHinata: `${environment.STEALTH_MAIN_URL}/v1.0/authentication/token`,
    forgotPassword: `${environment.STEALTH_MAIN_URL}/v1.0/authentication/forgot-password`,
    resetPassword: `${environment.STEALTH_MAIN_URL}/v1.0/authentication/set-password`,
    verifyEmail: `${environment.STEALTH_MAIN_URL}/v1.0/authentication/register/complete`,
    googleLogin: `${environment.STEALTH_MAIN_URL}/v1.0/authentication/login/google`,
    logoutUser: `${environment.STEALTH_MAIN_URL}/v1.0/authentication/logout`,
    currentUserSession: `${environment.STEALTH_MAIN_URL}/v1.0/session`,
    allUserSessions: `${environment.STEALTH_MAIN_URL}/v1.0/session/list`,
    profileDetails: `${environment.STEALTH_MAIN_URL}/v1.0/profile/detail`,
    updateEmailInit: `${environment.STEALTH_MAIN_URL}/v1.0/profile/update-email-init`,
    updateEmailComplete: `${environment.STEALTH_MAIN_URL}/v1.0/profile/update-email-complete`,
    updatePassword: `${environment.STEALTH_MAIN_URL}/v1.0/profile/update-password`,
    searchUsers: `${environment.STEALTH_MAIN_URL}/v1.0/org/:orgId/search?email=:email`,
    googleOAuthLogin: `${environment.STEALTH_WEBHOOK_URL}/v1.0/authentication/google`,
    appleOAuthLogin: `${environment.STEALTH_WEBHOOK_URL}/v1.0/authentication/apple`,
    updateMeta: `${environment.STEALTH_MAIN_URL}/v1.0/profile/meta`,
    googleOneTapLogin: `${environment.STEALTH_WEBHOOK_URL}/v1.0/authentication/google/one-tap`,
    // HIRUZEN URLs
    createOrganization: `${environment.HYDRO_MAIN_URL}/v1.0/`,
    getOrganizationDetails: `${environment.HYDRO_MAIN_URL}/v1.0/`,
    getOrganizationDetailsById: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/detail`,
    updateOrganizationDetailsById: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/detail`,
    getOrganizationPreference: `${environment.HYDRO_MAIN_URL}/v1.0/`,
    getOrganizationsForUser: `${environment.HYDRO_MAIN_URL}/v1.0/all`,
    createApp: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/apps`,
    createHospital: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/hospitals`,

    // Patient urls
    getPatientByOrganizationAndHospital: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/patients?last_id=:lastId&limit=:limit`,
    createPatientByOrganizationAndHospital: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/patients`,
    getPatientDetailsById: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/patients/:patientId`,
    getPatientDetailsByContact: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/patients/?phone=:phone`,
    getSignedURLForPatientDocUpload: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/patients/:patientId/upload`,
    notifyServerFileUploadedURL: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/patients/:patientId/upload`,
    getStudyIdForPatient: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/patients/:patientId/studies/:type?last_id=:lastId&limit=:limit`,
    getLabEvents: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/patients/:patientId/labevents/:studyId`,
    // getSignedUrlsToViewEchoFiles: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/patients/:patientId/upload/echo/assets/:studyId`,
    getSignedUrlsToViewEchoFiles: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/studies/:studyId/assets/echo`,
    getSignedUrlsToViewEcgFiles: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/studies/:studyId/assets/ecg`,
    getSignedUrlByEchoFileId: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/studies/:studyId/assets/:studyType/output/:assetId`,

    getECGMachineMeasurements: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/studies/:studyId/machine_measurements`,

    // /hosp/662f535e64481365cf25ca39/studies/66a8dc66b69f12fbd40ed499/assests/echo/'
    getPreviewForStudyId: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/studies/:studyType/:studyId`,
    getAllEchosForHospital: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/studies/echo?last_id=:lastId&limit=:limit`,
    getReportsForHospitalByStudyType: `${environment.HULKBUSTER_MAIN_URL}/v1.0/org/:orgId/hosp/:hospitalId/studies/:studyType?last_id=:lastId&limit=:limit`,

    getApps: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/apps`,
    getHospitals: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/hospitals`,
    selectApps: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/apps/:appId/select`,
    getAppDetails: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    getHospitalDetails: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/hospitals/:appId`,
    updateHospital: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/hospitals/:appId`,
    deleteHospital: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/hospitals/:appId`,

    updateApp: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    deleteApp: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    createTeamMember: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/team/`,
    getTeam: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/team/?userId=:userId&page=:page&pageSize=:pageSize`,
    getTeamMember: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    updateTeamMember: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    deleteTeamMember: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    getRoles: `${environment.HYDRO_MAIN_URL}/v1.0/role`,
    getDomains: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/domains/?searchQuery=:searchQuery&page=:page&pageSize=:pageSize`,
    getDomainDetails: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/domains/:domainId`,
    createDomain: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/domains`,
    deleteDomain: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/domains/:domainId`,
    getCountryList: `${environment.HYDRO_MAIN_URL}/v1.0/countries`,
    getBlogs: `${environment.HULKBUSTER_MAIN_URL}/blogs`,
    getLocationDetailsForOrg: `${environment.HYDRO_MAIN_URL}/v1.0/org/:orgId/location`,

    // CHOJI URLs
    search: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/search`,
    explore: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/explore`,
    exploreFolder: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/explore/folders`,
    directUpload: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/upload/direct`,
    signedUrlUpload: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/upload/signed-url`,
    urlsUpload: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/upload/urls`,
    createFolder: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/folders`,
    filesUrl: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/files`,
    transformationsUrl: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/files/transformations`,
    foldersUrl: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/folders`,
    ancestors: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/folders/:folderId/ancestors`,
    download: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/proxy/download`,
    downloadOpen: `${environment.CHOJI_PNL_URL}/v1.0/proxy/download`,
    plugins: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/playground/plugins`,
    playgroundDefault: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/playground/default`,
    credentials: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/credentials`,
    presets: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/presets`,
    updateFile: `${environment.CHOJI_PNL_URL}/v1.0/files/:fileId`,

    // GARRA URLS
    webhooks: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/webhook-configs`,
    webhook: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/webhook-configs/:webhookId`,
    testWebhook: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/webhook-configs/test`,
    webhookEvents: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/events`,
    webhookLogs: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/logs`,

    // ITACHI URLS
    insights: `${environment.ITACHI_PNL_URL}/v1.0/org/:orgId/insights`,

    // JIRAIYA URLS
    zones: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/zones`,
    zone: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/zones/:zoneId`,
    zoneQualityOptions: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/zones/quality-options`,
    datasourceSchemas: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/schemas`,
    datasourceSchema: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/schemas/:datasourceType`,
    datasources: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources`,
    datasource: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/:datasourceId`,
    datasourceAssets: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/:datasourceId/assets`,
    dropboxDatasourceOAuthRedirectUri: `${environment.JIRAIYA_WEBHOOK_URL}/v1.0/datasources/dropbox/auth`,
    purgeCdns: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/cdns/purge`,
    purgeCdnsAll: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/cdns/purge/all`,
    jobs: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs`,
    activeJobCount: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs/state/active`,
    bulkDownload: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs/download/bulk`,
    bulkValidate: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs/validate/bulk`,
    transformationDetail: `${environment.JIRAIYA_CDN_DOMAIN}/context`,
    signedURLs: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/signed-urls`,

    // ITACHI URLS
    transformationList: `${environment.ITACHI_PNL_URL}/v1.0/org/:orgId/transformations/list`,

    // RUPIKA URLS:
    subscriptions: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions`,
    activeSubscription: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/active-subscription`,
    cancelSubscription: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/cancel-subscription`,
    upcomingSubscription: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/upcoming-subscription`,
    plan: `${environment.RUPIKA_PNL_URL}/v1.0/plans/:id`,
    plans: `${environment.RUPIKA_PNL_URL}/v1.0/plans`,
    addOn: `${environment.RUPIKA_PNL_URL}/v1.0/addons/:id`,
    addOns: `${environment.RUPIKA_PNL_URL}/v1.0/addons`,
    cards: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/cards`,
    updateCards: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/cards/:paymentMethodId`,
    initCard: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/cards/init`,
    invoices: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/invoices`,
    pgInvoice: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/invoices/pgId/:pgId`,
    pgInvoiceOpen: `${environment.RUPIKA_PNL_URL}/v1.0/invoices/pgId/:pgId`,
    subscriptionUsage: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/usage/subscription`,
    addonsUsage: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/usage/addons`,
    refunds: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/refunds`,
    payments: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/payments`,
    customerAddOns: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/customerAddons`,
    billingAddress: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/customers/billingAddress`,
    currency: `${environment.RUPIKA_PNL_URL}/v1.0/currency`,
    getChangeActionType: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/change-action-type`,

    // SHIRAYUKI URLS
    auditLogs: `${environment.SHIRAYUKI_PNL_URL}/v1.0/org/:orgId/logs`,
    auditLog: `${environment.SHIRAYUKI_PNL_URL}/v1.0/org/:orgId/logs/:logId`,
};

export default URLS;
