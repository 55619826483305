import { combineReducers } from "redux";
import authReducer from "./reducers/auth.reducer";
import orgReducer from "./reducers/organization.reducer";
import uploadReducer from "./reducers/upload.reducer";
// import playgroundReducer from "./reducers/playground.reducer";
import teamReducer from "./reducers/team.reducer";
import zoneReducer from "./reducers/zone.reducer";
import datasourceReducer from "./reducers/datasource.reducer";
import menuReducer from "./reducers/menu.reducer";
// import directoryReducer from "./reducers/directory.reducer";
import userAgentReducer from "./reducers/useragent.reducer";
import paymentReducer from "./reducers/payment.reducer";
import segmentReducer from "./reducers/segment.reducer";
import { selectionReducer } from "./reducers/selection.reducer";
// import bulkPlaygroundReducer from "./reducers/bulkPlayground.reducer";
import domainReducer from "./reducers/domain.reducer";
import websiteConfigReducer from "./reducers/websiteConfig.reducer";

const rootReducer = combineReducers({
    authenticationDetails: authReducer,
    organizationDetails: orgReducer,
    uploadDetails: uploadReducer,
    // playgroundDetails: playgroundReducer,
    // teamDetails: teamReducer,
    // zoneDetails: zoneReducer,
    // datasourceDetails: datasourceReducer,
    menuDetails: menuReducer,
    // directoryDetails: directoryReducer,
    userAgentDetails: userAgentReducer,
    // paymentDetails: paymentReducer,
    segmentDetails: segmentReducer,
    // selectionDetails: selectionReducer,
    // bulkPlaygroundDetails: bulkPlaygroundReducer,
    // domainDetails: domainReducer,
    // websiteConfig: websiteConfigReducer,
});

export default rootReducer;
