import React, { createContext, useContext, useState } from "react";

const GauzeContext = createContext(null);

const GauzeContextProvider = ({ children }) => {
    const temp = "";
    return <GauzeContext.Provider value={{}}>{children}</GauzeContext.Provider>;
};

const useGauzeContext = () => useContext(GauzeContext);

export { GauzeContextProvider, useGauzeContext };
