import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import environment from "../../environment";
import EventEmitter from "../events";
import {
    AuthorisedUser,
    FCScriptInitiated,
    SegmentScriptInitiated,
    SetFcRestoredId,
    widgetLoaded,
    ReportIssueClosed,
    UTM_REFERRAL_EVENT,
    PAYMENT_SUCCESS,
    PAYMENT_FAILURE,
} from "../events/events";
import { setReportIssue } from "../redux/actions/useragentAction";
import { initiateScript } from "../redux/actions/segmentActions";
import { UserAgentAction, addUserAgent } from "../redux/actions/useragentAction";
import AuthService from "../../services/auth.service";
import { initializeSegmentIdentity } from "../utils";
// import useTrackingProperties from "./useTrackingProperties";

function paymentStatusCallback(event, message) {
    const source = "pixelbin-console-payment-status";
    const messagePayload = {
        event,
        source,
    };
    switch (event) {
        case PAYMENT_SUCCESS:
            messagePayload["data"] = { payment: message?.payment };
            break;
        case PAYMENT_FAILURE:
            messagePayload["data"] = { payment: { status: "failed" } };
            break;
        default:
            return;
    }
    console.log(JSON.stringify(messagePayload));
    window.postMessage(JSON.stringify(messagePayload));
}

const useScriptsHooks = () => {
    // const trackingProperties = useTrackingProperties();
    const { isUserLoggedIn, user } = useSelector((state) => state.authenticationDetails);
    const [isFcScriptInitiated, setFcScriptInitiated] = useState(false);
    const isSegmentScriptInitiated = useSelector(
        (state) => state.segmentDetails.isSegmentScriptInitiated,
    );
    const dispatch = useDispatch();

    // useEffect(() => {
    //     addUserAgent(window.navigator.userAgent, dispatch);

    //     EventEmitter.on(SetFcRestoredId, function (event, payload) {
    //         let restoreId = payload?.freshChatDetails?.restoreId;
    //         updateMeta({ restoreId: restoreId });
    //     });
    //     EventEmitter.on(FCScriptInitiated, function () {
    //         setFcScriptInitiated(true);
    //     });
    //     // added to get to know Freshchat widget is loaded in website
    //     // used in UploadStatusToast component to position the toast
    //     // also in storage page to place mobile upload icon
    //     EventEmitter.on(widgetLoaded, function () {
    //         dispatch({
    //             type: UserAgentAction.SetFcWidgetLoaded,
    //             payload: true,
    //         });
    //     });

    //     /**
    //      * Added `PAYMENT_SUCCESS` and `PAYMENT_FAILURE` event callback function
    //      * for printing payment details in console and sending postmessage
    //      *  for mobile team to access the payment status.
    //      */
    //     EventEmitter.on(PAYMENT_SUCCESS, paymentStatusCallback);
    //     EventEmitter.on(PAYMENT_FAILURE, paymentStatusCallback);
    // }, []);

    const updateMeta = (updateOpts) => {
        AuthService.updateMeta(updateOpts)
            .then((res) => {})
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        window["dataLayer"] = window["dataLayer"] || [];
        if (environment.VALUE !== "development" && environment.GTM_CONTAINER_ID) {
            window["dataLayer"].push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js",
            });
            const f = document.getElementsByTagName("script")[0],
                j = document.createElement("script"),
                dl = "&l=dataLayer";
            j.async = true;
            j.defer = true;
            const i = environment.GTM_CONTAINER_ID;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f?.parentNode?.insertBefore(j, f);

            // Second GTM (noscript) iframe
            const noscriptTag = document.createElement("noscript");
            const iframeTag = document.createElement("iframe");
            iframeTag.src = `https://www.googletagmanager.com/ns.html?id=${i}`;
            iframeTag.height = "0";
            iframeTag.width = "0";
            iframeTag.style.display = "none";
            iframeTag.style.visibility = "hidden";
            noscriptTag.appendChild(iframeTag);
            document.body.appendChild(noscriptTag);

            // Usersnap script setup
            window.onUsersnapLoad = function (api) {
                api.init();
            };
            const usersnap_key = "84de98e8-9dc7-4c84-8a46-a83318bc233c";
            // const usersnap_key = environment.USERSNAP_API_KEY;
            const usersnapScript = document.createElement("script");
            usersnapScript.defer = true;
            usersnapScript.src = `https://widget.usersnap.com/global/load/${usersnap_key}?onload=onUsersnapLoad`;
            document.getElementsByTagName("head")[0].appendChild(usersnapScript);
        }
    }, []);

    useEffect(() => {
        if (isUserLoggedIn && isFcScriptInitiated) {
            EventEmitter.dispatch(AuthorisedUser);
        }
    }, [isUserLoggedIn, isFcScriptInitiated]);

    EventEmitter.on(SegmentScriptInitiated, function () {
        initiateScript(
            {
                isSegmentScriptInitiated: true,
            },
            dispatch,
        );
    });
    // EventEmitter.on(ReportIssueClosed, function () {
    //     setReportIssue(
    //         {
    //             isReportIssueCliked: false,
    //         },
    //         dispatch,
    //     );
    // });

    // useEffect(() => {
    //     if (isSegmentScriptInitiated) {
    //         initializeSegmentIdentity(user);
    //     }
    // }, [isSegmentScriptInitiated, isUserLoggedIn]);

    // useEffect(() => {
    //     const isBrowser = typeof window !== "undefined";
    //     if (isBrowser) {
    //         try {
    //             let utmData = sessionStorage.getItem("utm_params");
    //             if (utmData) {
    //                 EventEmitter.dispatch(UTM_REFERRAL_EVENT, JSON.parse(utmData));
    //             }
    //             sessionStorage.removeItem("utm_params");
    //         } catch (e) {
    //             console.log("Error accessing sessionStorage:", e);
    //         }
    //     }
    // }, [isSegmentScriptInitiated]);

    // useEffect(() => {
    //     const isBrowser = typeof window !== "undefined";
    //     if (isBrowser) {
    //         try {
    //             const params = new URLSearchParams(window.location.search);
    //             const utmCampaign = params.get("utm_campaign");
    //             if (utmCampaign) {
    //                 let utmParams = {
    //                     utmSource: params.get("utm_source"),
    //                     utmMedium: params.get("utm_medium"),
    //                     utmCampaign: utmCampaign,
    //                 };
    //                 sessionStorage.setItem("utm_params", JSON.stringify(utmParams));
    //             }
    //         } catch (e) {
    //             console.log("Error accessing sessionStorage:", e);
    //         }
    //     }
    // }, []);

    // Refs
    // https://ilxanlar.medium.com/you-shouldnt-rely-on-css-100vh-and-here-s-why-1b4721e74487
    // https://dev.to/nirazanbasnet/dont-use-100vh-for-mobile-responsive-3o97
    // useEffect(() => {
    //     function setVisibleHeight() {
    //         document.documentElement.style.setProperty("--doc-height", `${window.innerHeight}px`);
    //     }

    //     window.addEventListener("resize", setVisibleHeight);
    //     window.addEventListener("orientationchange", setVisibleHeight);

    //     return () => {
    //         window.removeEventListener("resize", setVisibleHeight);
    //         window.removeEventListener("orientationchange", setVisibleHeight);
    //     };
    // }, []);

    // useEffect(() => {
    //     if (trackingProperties) {
    //         EventEmitter.trackingProperties = {
    //             ...EventEmitter.trackingProperties,
    //             ...trackingProperties,
    //         };
    //     }
    // }, [trackingProperties]);

    return "";
};

export default useScriptsHooks;
