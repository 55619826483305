import React from "react";
import styled from "styled-components";
import LoaderGif from "../assets/loader.gif";

const LoaderImg = styled.div`
    width: ${(props) => (props.width ? props.width : "45px")};
    height: ${(props) => (props.width ? props.width : "45px")};
`;

function LoaderIcon(props) {
    // return <LoaderImg data-testid="loader-icon" src={LoaderGif} {...props} />;

    return (
        <LoaderImg width={props.height}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                <radialGradient
                    id="a8"
                    cx=".66"
                    fx=".66"
                    cy=".3125"
                    fy=".3125"
                    gradientTransform="scale(1.5)"
                >
                    <stop offset="0" stopColor="#1C240F"></stop>
                    <stop offset=".3" stopColor="#1C240F" stopOpacity=".9"></stop>
                    <stop offset=".6" stopColor="#1C240F" stopOpacity=".6"></stop>
                    <stop offset=".8" stopColor="#1C240F" stopOpacity=".3"></stop>
                    <stop offset="1" stopColor="#1C240F" stopOpacity="0"></stop>
                </radialGradient>
                <circle
                    style={{ transformOrigin: "center" }}
                    // transform-origin="center"
                    fill="none"
                    stroke="url(#a8)"
                    strokeWidth="15"
                    strokeLinecap="round"
                    strokeDasharray="200 1000"
                    strokeDashoffset="0"
                    cx="100"
                    cy="100"
                    r="70"
                >
                    <animateTransform
                        type="rotate"
                        attributeName="transform"
                        calcMode="spline"
                        dur="2"
                        values="360;0"
                        keyTimes="0;1"
                        keySplines="0 0 1 1"
                        repeatCount="indefinite"
                    ></animateTransform>
                </circle>
                <circle
                    // transform-origin="center"
                    style={{ transformOrigin: "center" }}
                    fill="none"
                    opacity=".2"
                    stroke="#1C240F"
                    strokeWidth="15"
                    strokeLinecap="round"
                    cx="100"
                    cy="100"
                    r="70"
                ></circle>
            </svg>
        </LoaderImg>
    );
}

export default LoaderIcon;
