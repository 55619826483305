import React from "react";
import styled from "styled-components";
import LoaderIcon from "./LoaderIcon";

const LoaderContainer = styled.div`
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: rgba(0, 0, 0, 0.5);
    // backdrop-filter: blur(10px);
`;

const Msg = styled.p`
    font-family: var(--body-font);
    font-size: 20px;
    color: var(--white-90);
    margin-top: 10px;
`;

const LoaderSpinner = styled.div`
    border: 5px solid transparent;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    border: 3px solid var(--white-90);
    display: inline-block;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 65px;
        height: 65px;
        border-radius: 50%;
        border: 5px solid transparent;
        border-bottom-color: #5eb1ff;
        border-right-color: #5eb1ff;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Loader = () => {
    return (
        <LoaderContainer>
            <LoaderIcon />
        </LoaderContainer>
    );
};

export default Loader;
